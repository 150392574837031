import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import Form from './Form';

import { ImPhone } from 'react-icons/im';
import { BsClockFill } from 'react-icons/bs';
import { FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';

const ContactInfo_3 = () => {

    const { rpdata } = useContext(GlobalDataContext);


    return (
        <div className='w-full pb-10 flex justify-center'>
            <div className='w-[90%] block md:flex py-14 md:py-[100px]'>
                <div className='w-full md:w-[55%] pb-10 md:px-8 px-5 md:pb-0 block space-y-10'>
                    <h2 className="color-2 font-bold">{rpdata?.dbSlogan?.[2].slogan}</h2>
                    <div className=' w-full h-[12%]  rounded-3xl bgBloque flex items-center justify-start px-10 text-white shadowper2'>
                        {
                            rpdata?.dbPrincipal?.phones.slice(0, 1).map((phone, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={`tel:+1${phone.phone}`}
                                        className='flex items-center justify-center space-x-3 py-2 md:space-x-8'
                                    >
                                        <ImPhone className='text-[30px] md:text-[35px]'/>
                                        <h5 className='pl-4 text-[22px] md:text-[32px]'>
                                            {phone.phone}
                                            <span className='pl-2'>
                                                {phone.name}
                                            </span>
                                        </h5>
                                    </a>
                                )
                            })

                        }
                    </div>
                    <div className=' w-full h-[12%]  rounded-3xl bgBloque flex items-center justify-start px-10 text-white shadowper2'>
                        {
                            rpdata?.dbPrincipal?.phones.slice(1, 2).map((phone, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={`tel:+1${phone.phone}`}
                                        className='flex items-center justify-center space-x-3 py-2 md:space-x-8'
                                    >
                                        <ImPhone className='text-[30px] md:text-[35px]'/>
                                        <h5 className='pl-4 text-[22px] md:text-[32px]'>
                                            {phone.phone}
                                            <span className='pl-2'>
                                                {phone.name}
                                            </span>
                                        </h5>
                                    </a>
                                )
                            })

                        }
                    </div>
                    
                    <div className=' w-full h-[12%]  rounded-3xl bgBloque flex items-center justify-start px-10 py-2 space-x-3 md:space-x-10 text-white shadowper2'>
                        <FaMapMarkerAlt className='text-[30px] md:text-[35px]'/>
                        <h5 className='pl-4 text text-[22px]-md:[32px]'>
                            {rpdata?.dbPrincipal?.location?.[0]?.address}
                        </h5>

                    </div>
                    <div className=' w-full h-[12%]  rounded-3xl bgBloque flex items-center justify-start px-10 py-2 space-x-3 md:space-x-10 text-white shadowper2'>
                        <FaCalendarAlt className='text-[30px] md:text-[35px]'/>
                        <h5 className='pl-4 text text-[22px]-md:[32px]'>
                            {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                        </h5>

                    </div>
                    <div className=' w-full h-[12%]  rounded-3xl bgBloque flex items-center justify-start px-10 py-2 space-x-3 md:space-x-10 text-white shadowper2'>
                        <BsClockFill className='text-[30px] md:text-[35px]'/>
                        <h5 className='pl-4 tex text-[22px]tmd:-[32px]'>
                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                        </h5>

                    </div>

                </div>
                <div className='w-full md:w-[45%]'>
                    <div className='w-[80%] md:w-[70%] text-center md:text-center mx-auto md:ml-10'>
                        <h3>Contact US</h3>
                        <div className="border border-black px-5 rounded-2xl">
                            <Form />
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default ContactInfo_3